import React from 'react';
import Layout from 'components/Layout';
import SEO from 'components/Seo';
import RenderSection from 'components/RenderSection';
import { graphql } from 'gatsby';
import { first } from 'lodash';

const Landing = ({
  data: { datoCmsLanding, datoCmsPark, allDatoCmsPark } = {},
  pageContext,
}) => {
  const {
    sections,
    seo,
    ctaName,
    ctaUrl,
    ctaEventName,
    isExternalLink,
    ctaOfferUrl,
    ctaOfferName,
  } = datoCmsLanding || {};

  return (
    <Layout
      datoCmsPark={datoCmsPark}
      allDatoCmsPark={allDatoCmsPark}
      ctaName={ctaName}
      ctaUrl={ctaUrl}
      ctaEventName={ctaEventName}
      isExternalLink={isExternalLink}
      ctaOfferUrl={ctaOfferUrl}
      ctaOfferName={ctaOfferName}
      urlsTranslations={pageContext?.urlsTranslations}
      menuLandingPages={pageContext?.menuLandingPages}
      parkSlug={pageContext?.parkSlug}
    >
      <SEO
        title={seo?.title}
        description={seo?.description}
        jsonLdBreadcrumb={pageContext?.breadcrumbList}
        meta={[
          {
            name: 'robots',
            content: 'index, follow',
          },
        ]}
      />

      {sections?.map(({ id, title, contents }) => (
        <RenderSection
          key={id}
          contents={contents}
          title={title}
          isFirst={first(sections)?.id === id}
        />
      ))}
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $locale: String!, $parkSlug: String!) {
    datoCmsLanding(id: { eq: $id }, locale: { eq: $locale }) {
      title
      ctaUrl
      ctaName
      ctaEventName
      isExternalLink
      ctaOfferUrl
      ctaOfferName
      seo {
        title
        description
      }
      sections {
        ...SectionContent
      }
    }
    datoCmsPark(locale: { eq: $locale }, slug: { eq: $parkSlug }) {
      ...ParkLayoutContent
    }
    allDatoCmsPark(
      filter: { locale: { eq: $locale } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        ...ParkLayoutContent
      }
    }
  }
`;

export default Landing;
